.collection {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: black;
    &__close {
        position: absolute;
        top:2vh;
        right:2vh;
        z-index: 1;
    }
&__poems {
    display: flex;
    gap:5vh;
    overflow-x: scroll;
    padding:1vh;
}   
    .poem {
       
        height:98vh;
        width:66vh;
        min-height: 98vh;
        padding: 10vh 8vh 8vh 10vh;
        flex: 10 0 auto;
        h1 {
            font-size: 3.5vh;
            line-height: 4.4vh;
            margin-bottom: 2vh;
        }
        h2 {
            margin: 2vh 0;
            font-size: 1.8vh;
            line-height: 1.8vh;
        }
        p {
            font-size: 1.8vh;
            line-height: 2.4vh;
            min-height: 2.4vh;
        }
        &__number {
            font-size: 1.8vh;
            line-height: 2.4vh;
            min-height: 2.4vh;
        }
        &--cover {
            background-color: brown;
            color:white;
        }
        &__watermark {
            display: none;
        }
    }
}
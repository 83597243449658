.poetry-remixer {
  display: grid;
  grid-template-columns: 1fr 600px;
  gap: 2rem;
  &__input {
    .poetry-remixer__collections {
      display: block;
      button {
        margin-right: 0.25rem;
      }
      p {
        font-size: 0.825rem;
        line-height: 1.3;
      }
      @media only screen and (max-width: 960px) {
        display: none;
      }
    }
  }
  &__output {
    .poetry-remixer__collections {
      display: none;
      @media only screen and (max-width: 960px) {
        display: block;
      }
    }
  }
  @media only screen and (max-width: 960px) {
    display: block;
  }
  h1 {
    margin: 0;
    margin-bottom: 1rem;
  }
  &__nav {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }
  &__share,
  &__about {
    margin: 1rem 0;
    position: relative;
    font-size: 0.825rem;
    background-color: #eee;
    padding: 1rem;
    h2 {
      margin-top: 0;
      ~ h2 {
        margin-top: 1rem;
      }
    }
    p {
      max-width: 600px;
    }
    &__input-wrapper {
      display: flex;
      gap: 0.5rem;
    }
    input {
      padding: 0.25rem 1rem;
      width: 80%;
    }
  }
  &__button-wrapper {
    display: flex;
    gap: 0.5rem;
  }
  &__text-input {
    textarea {
      margin-top: 1rem;
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
    }
    &__chars {
      font-size: 0.825rem;
    }
    border-top: 1px dashed #ddd;
    margin: 1rem 0;
  }
  @media print {
    display: block;
    .poetry-remixer__input {
      display: none;
    }
    .poem__line--active span {
      &.cursor,
      &.ellipsis {
        display: none;
      }
    }
    .poem {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: none;
      padding-right: 6rem;
    }
  }
}

.poetry-remixer__input {
  margin: 1rem 0;
}
.poetry-remixer__textarea {
  display: block;
  min-height: 200px;
  width: 100%;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
}
.poetry-remixer__textarea--hide {
  display: none;
}
.poetry-remixer__button {
  padding: 0.25rem;
}

.keyboard {
  margin-top: 1rem;
  border-top: 1px dashed #ddd;
  &__buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    margin: 1rem 0;
  }
}
.poetry-remixer__output {
  margin: 1rem 0;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.poetry-remixer--print-view {
  .poem__line--active {
    span.cursor,
    span.ellipsis {
      display: none;
    }
  }
  .poem__word-edit {
    display: none;
  }
  .poem__word {
    outline: none;
  }
}

.print-view {
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  @media print {
    padding: 1rem 0;
  }
  &__toolbar {
    position: fixed;
    top: 0.25rem;
    right: 0.25rem;
    display: flex;
    gap: 0.25rem;
    @media print {
      display: none;
    }
  }
  &__instructions {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    font-size: 0.825rem;
    color: white;
    width: 100%;
    text-align: center;
    margin: 0;
    @media print {
      display: none;
    }
  }
  canvas {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
  }
}

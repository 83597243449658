.poem {
    max-width: 600px;
    min-height: 900px;
    padding: 5rem 4rem 4rem 6rem;
    background: #fffdd0;
    position: relative;
    color: #343434;
    box-sizing: border-box;
    font-family: 'Georgia', 'times new roman', serif;
    font-size: 1.1rem;
    line-height: 1.5;
    overflow: hidden;
    h1 {
      font-style: italic;
      font-size: 2rem;
      line-height: 2.5rem;
      font-weight: normal;
    }
    h2 {
      font-size: 0.875rem;
      text-transform: uppercase;
      font-family: Gill Sans, Gill Sans MT, Calibri, Arial, sans-serif;
      margin-bottom: 2rem;
      font-weight: normal;
    }
    p {
      margin: 0;
      font-size: 1rem;
      line-height: 1.4rem;
      min-height: 1.4rem;
    }
    &__line {
        position: relative;
        &::before {
            position: absolute;
            width: 100%;
            margin-left:-6rem;
            margin-right:-6rem;
            height: 100%;
            content: "";
        }
    }
    &__word {
        position: relative;
        &--highlighted {
            outline:red solid 4px;
        }
    }
    &__word-edit {
        position: absolute;
        z-index: 1;
        background: #fafafa;
        padding:0.25rem;
        display: flex;
        width:100%;
        display: flex;
        flex-direction: column;
        width: 100px;
        top:1.5rem;
        left:-25px;
        button {
            margin:0.25rem;
        }
    }
    @media only screen and (max-width: 600px) {
        padding: 10vh 8vh 8vh 10vh;
        height:98vh;
        width:66vh;
        min-height: 98vh;
        h1 {
            font-size: 3.5vh;
            line-height: 4.4vh;
            margin-bottom: 2vh;
        }
        h2 {
            margin: 2vh 0;
            font-size: 1.8vh;
            line-height: 1.8vh;
        }
        p {
            font-size: 1.8vh;
            line-height: 2.4vh;
            min-height: 2.4vh;
        }
        &__number {
            font-size: 1.8vh;
            line-height: 2.4vh;
            min-height: 2.4vh;
        }
      }
    &__number {
      position: absolute;
      bottom: 2rem;
      width: 100%;
      left: 0;
      text-align: center;
    }
    &__line {
      .ellipsis,
      .cursor {
        display: none;
      }
      &--active {
        span {
          
          &.ellipsis {
            display: block;
            position: absolute;
            margin-left: -2rem;
          }
          &.cursor {
            display: inline;
            animation: cursor-blink 1.5s steps(2) infinite;
          }
        }
      }
    }
    &__watermark {
        position: absolute;
        bottom:0.5rem;
        right:1rem;
        font-size: 0.7rem;
        opacity: 0.5;
        font-style: italic;
        letter-spacing: 1px;
    }
  }
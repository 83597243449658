.App {
  max-width: 96%;
  margin: 1rem auto;
}
button {
    background: #F4DEB3;
    color:#000;
    border-radius: 2rem;
    border: none;
    padding: 0.5rem 1rem !important;
    display: inline-block;
}
.btn--primary {
  background-color: #ffbe99;
}
.btn--secondary {
  background-color: #ffcb69;
}
.btn--tertiary {
  background-color: darkslategrey;
  color: white;
}
footer {
  padding:1rem 0;
  border-top:1px solid #ddd;
  font-size: 0.825rem;
   @media print {
      display: none;
  }
}

.options-dropdown {
    border-top: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;
    margin: 1rem 0;
    &__dropdown-title {
      font-weight: bold;
      background: transparent;
      border: none;
  
      display: block;
      width: 100%;
      padding: 0.5rem 0;
      text-align: left;
    }
    &__field {
      margin: 1rem 0;
      input,
      label {
        display: block;
      }
      label {
        margin-bottom: 0.25rem;
        font-weight: bold;
        font-size: 0.825rem;
      }
      input {
        width: 100%;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        border-radius: 0.5rem;
        border: 1px solid #2a2a2a;
      }
    }
  }
  